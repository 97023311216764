<template>
  <div style="display: contents;">
    <component :is="disconnectedComponent" :class="{ 'hideDisconnected': $root.isAppDisconnected === false }"></component>

    <keep-alive v-if="$root.isAppDisconnected === false && $store.getters.getProduct === 'personal' && ($route.name === 'meeting' || $route.name === 'appointment' || $route.name === 'waiting_room')">
      <router-view />
    </keep-alive>
    <router-view v-else-if="$root.isAppDisconnected === false" />
    <div v-if="$store.getters.getUser" >
      <div v-for="(notification, index) in notifications" :key="index">
        <download-scriptix-transcription
        :key="getUniqueKey(notification, 'download-scriptix-transcription')"
        :notificationCount="notifications.length"
        :index="index"
        :notification="notification"
        @close="removeNotification(index)"
        @triggerScriptixTranscription="triggerScriptixTranscription"></download-scriptix-transcription>
      </div>
      <div class="notification-count" v-if="notifications.length > 1">{{ notifications.length }}</div>
    </div>
  <Toast />
  </div>
</template>

<script>
import Moment from 'moment-timezone'
import Toast from 'primevue/toast'
export default {
  name: 'App',
  data () {
    return {
      notifications: [],
      progress: 0,
      usedKeys: new Set()
    }
  },
  components: {
    disconnected: () => import('./components/MeetingDisconnected'),
    Toast
  },
  computed: {
    disconnectedComponent () {
      return 'disconnected'
    }
  },
  methods: {
    getUniqueKey (notification, label = '') {
      const key = notification.data['x-scriptix-document'] || notification.data['x-scriptix-session']
      if (this.usedKeys.has(key)) {
        return null
      }
      this.usedKeys.add(key)
      return `${label}-${key}`
    },
    removeNotification (index) {
      this.notifications.splice(index, 1)
    },
    downloadTranscription (transcription) {
      if (!this.$store.getters.getUser) {
        return
      }
      const key = transcription.data['x-scriptix-document'] || transcription.data['x-scriptix-session']
      if (this.usedKeys.has(key)) {
        return
      }
      this.notifications.push({ ...transcription, progress: this.progress })
    },
    onProgress (progress, index) {
      this.notifications[index].progress = progress
    },
    async triggerScriptixTranscription (sessionId, documentId, index) {
      if (!sessionId || !documentId) {
        return
      }
      try {
        const response = await this.$axios.downloadScriptixTranscript(sessionId, documentId, this.$store.getters.getUser.scriptix_api_key, (progress) => this.onProgress(progress, index))
        const locale = this.$store.getters.getUser.locale
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
        const downloadUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = `transcription${Moment().locale(locale).format('DD_MMM_yyyy')}.doc` // The name for the downloaded file
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(downloadUrl)

        const deleteResponse = await this.$axios.deleteScriptixTranscript(sessionId, documentId, this.$store.getters.getUser.scriptix_api_key)
        if (deleteResponse.status === 204) {
          console.log('Transcription deleted successfully')
        }
      } catch (error) {
        console.error('There was an error downloading the file:', error)
      }
    }
  },
  mounted () {
    console.log('Scriptix Transcription Modal Mounted')
    this.$webrtc.EventBus.$on('onScriptixTranscriptionReceived', this.downloadTranscription)

    if (this.$store.getters.getProduct === 'personal') {
      clearInterval(this.$store.getters.getAlwaysAvailableInterval)
      this.$store.commit('setAlwaysAvailableInterval', null)
      const intervalId = setInterval(() => {
        if (this.$store.getters.getUser && this.$store.getters.getUser.always_call_available === true) {
          if (this.$store.getters.getMeetingStarted === false) {
            this.$webrtc.EventBus.$emit('onStatusChanged', {
              'status': 'online'
            })
          }
        }
      }, 15000)
      this.$store.commit('setAlwaysAvailableInterval', intervalId)
    }
  },
  beforeDestroy () {
    this.$webrtc.EventBus.$off('onScriptixTranscriptionReceived', this.downloadTranscription)
  },
  destroyed () {
    if (this.$store.getters.getProduct === 'personal') {
      clearInterval(this.$store.getters.getAlwaysAvailableInterval)
      this.$store.commit('setAlwaysAvailableInterval', null)
    }
  }
}
</script>

<style lang="scss" scoped>
  .hideDisconnected {
    display: none !important;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
  .notification-count {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 120px;
        right: 10px;
        background-color: #073C5A;
        color: white;
        border-radius: 50%;
        padding: 0.5rem;
        font-size: 0.8rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
    ::v-deep .p-toast .p-toast-message.p-toast-message-warn,
    ::v-deep .p-toast .p-toast-message.p-toast-message-success,
    ::v-deep .p-toast .p-toast-message.p-toast-message-error {
        background-color: #FFF;
        color: #000;
        .p-toast-message-content .p-toast-message-text .p-toast-detail {
            font-size: 0.7rem;
        }
    }
</style>
